import styled from "@emotion/styled"
import { device } from "./device"

const AppWrapper = styled.div`
    margin: 0 auto;
    padding: 0 20px;

    @media ${device.tablet} {
        max-width: calc(1200px + 16px * 2);
        padding: 0 50px;
    }
`

export default AppWrapper
