import styled from "@emotion/styled"
import { device } from "../styles/device"
import { colors } from "../styles/colors"
import { fontFamily } from "../styles/fonts/fontFamily"

const H3 = styled.h3`
    font-size: 3rem;
    line-height: 1.3;
    font-family: ${fontFamily.manrope};
    color: ${colors.black};
    border-bottom: none;
    padding-bottom: 5px;
    margin: 0.5em 0;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 500;

    @media ${device.tablet} {
        font-size: 3.6rem;
    }
`
export default H3
