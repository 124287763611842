import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { device } from "../../styles/device"
import AppWrapper from "../../styles/AppWrapper"
import SocialLinks from "../Footer/SocialLinks"
import Newsletter from "../Footer/Newsletter"

const Footer = () => {
    return (
        <FooterTag id="footer">
            <AppWrapper>
                <FooterWrapper>
                    <TopFooter>
                        <Newsletter />
                    </TopFooter>
                    <BottomFooter>
                        <SocialLinks />
                    </BottomFooter>
                </FooterWrapper>
            </AppWrapper>
        </FooterTag>
    )
}
export default Footer

const FooterTag = styled.footer``

const FooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10rem;

    @media ${device.mobileL} {
        justify-content: flex-end;
        margin-top: 16rem;
    }

    @media ${device.tablet} {
        flex-direction: row-reverse;
    }
`

const TopFooter = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 100%;
    background-color: ${colors.purple};
    padding: 10rem 5%;
    justify-content: center;

    @media ${device.tablet} {
        flex-basis: 80%;
        flex-grow: 2;
        padding: 6rem 5%;
    }
`
const BottomFooter = styled.div`
    flex-basis: 100%;
    padding-bottom: 2rem;

    @media ${device.tablet} {
        flex-basis: 15%;
        padding-right: 5%;
        flex-grow: 1;
    }
`
