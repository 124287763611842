export const globalFonts = [
    {
        "@font-face": {
            fontFamily: "Manrope",
            src: "url('/fonts/manrope/ManropeVariable.ttf') format('truetype')",
            fontWeight: "1 999",
            fontStyle: "normal"
        }
    }
]
