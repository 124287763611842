import React from "react"
import Helmet from "react-helmet"
import striptags from "striptags"

export default React.memo(
    ({
        author,
        buildTime,
        description,
        siteUrl,
        isBlogPost,
        image,
        personalDescription,
        title,
        url,
        name,
        logo
    }) => {
        const baseSchema = [
            {
                "@context": "http://schema.org",
                "@type": "WebSite",
                url: siteUrl,
                name: author,
                description: striptags(personalDescription),
                image: `https://descriptive-profile-images.s3.amazonaws.com/johnnie-aiello-og-image.jpg`,
                sameAs: [
                    `https://twitter.com/johnnieaiello/`,
                    `https://www.instagram.com/johnnieaiello/`,
                    `https://www.linkedin.com/in/johnnieaiello/`,
                    `https://www.johnnieaiello.com`
                ]
            }
        ]

        const schema = isBlogPost
            ? [
                  ...baseSchema,
                  {
                      "@context": "http://schema.org",
                      "@type": "BreadcrumbList",
                      itemListElement: [
                          {
                              "@type": "ListItem",
                              position: 1,
                              item: {
                                  "@id": url,
                                  name: title
                              }
                          }
                      ]
                  },
                  {
                      "@context": "http://schema.org",
                      "@type": "BlogPosting",
                      "@id": url,
                      url: url,
                      name: name,
                      headline: title,
                      image: image,
                      description: striptags(description),
                      datePublished: buildTime,
                      dateModified: buildTime,
                      author: {
                          "@type": "Person",
                          name
                      },
                      publisher: {
                          "@type": "Person",
                          name,
                          logo: {
                              "@type": "ImageObject",
                              url: logo
                          }
                      },
                      mainEntityOfPage: {
                          "@type": "WebPage",
                          "@id": url
                      }
                  }
              ]
            : baseSchema

        return (
            <Helmet>
                {/* Schema.org tags */}
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>
        )
    }
)
