import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import { device } from "../../styles/device"

const SocialLinks = () => {
    const footerQuery = useStaticQuery(
        graphql`
            query {
                allSocialLinksJson {
                    nodes {
                        socialLinks {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `
    )

    const [footerInfo] = get(footerQuery, "allSocialLinksJson.nodes", [])
    const { socialLinks = [] } = footerInfo

    return (
        <>
            <SocialLinksContainer>
                {socialLinks.map(links => (
                    <SocialLinksWrapper key={links.id}>
                        <SocialLink
                            href={`${links.url}`}
                            target="_blank"
                            aria-label="Click here to visit Social Link."
                        >
                            {links.title}
                        </SocialLink>
                    </SocialLinksWrapper>
                ))}
            </SocialLinksContainer>
        </>
    )
}
export default SocialLinks

const SocialLinksContainer = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 4rem 0;

    @media ${device.tablet} {
        padding: 6rem 0;
    }
`

const SocialLinksWrapper = styled.li`
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    text-align: left;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const SocialLink = styled.a`
    text-decoration: none;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    color: ${colors.black};
    font-size: 1.8rem;
`
