import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import addToMailchimp from "gatsby-plugin-mailchimp"
import H3 from "../H3"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { device } from "../../styles/device"

const Newsletter = () => {
    const [email, setEmail] = useState("")
    const [status, setStatus] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = async event => {
        event.preventDefault()
        // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
        const { result, msg } = await addToMailchimp(email)
        result === "success" && setEmail("")
        // Removes the HTML returned in some response messages in case of error
        setMessage(msg.split("<")[0])
        setStatus(result)
    }

    const handleChange = event => setEmail(event.target.value)

    return (
        <NewsletterFormContainer>
            <H3
                css={css`
                    color: ${colors.tan};
                `}
            >
                Bring my weekly Quick Thoughts<Trademark>™</Trademark> to your
                inbox.
            </H3>
            <NewsletterForm
                name="Newsletter Form"
                method="post"
                onSubmit={handleSubmit}
            >
                <InputFieldContainer>
                    <InputFieldWrapper>
                        <Input
                            placeholder="Email address"
                            name="email"
                            type="email"
                            onChange={handleChange}
                            required
                            for="emailaddress"
                            aria-label="Email Address"
                        />
                        <StatusMessage status={status}>{message}</StatusMessage>
                    </InputFieldWrapper>
                </InputFieldContainer>

                <ButtonContainer>
                    <SubscribeButton type="submit" onClick={handleSubmit}>
                        Subscribe
                    </SubscribeButton>
                </ButtonContainer>
            </NewsletterForm>
        </NewsletterFormContainer>
    )
}

export default Newsletter

const NewsletterForm = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding-top: 25px;

    @media ${device.mobileL} {
        flex-direction: row;
        align-items: baseline;
        padding-top: 0;
    }
`

const Trademark = styled.sup`
    font-size: 1.4rem;
    @media ${device.tablet} {
        font-size: 2rem;
    }
`

const NewsletterFormContainer = styled.div``

const InputFieldContainer = styled.div`
    flex-basis: 100%;
    position: relative;

    @media ${device.mobileL} {
        margin-right: 1rem;
        flex-basis: 75%;
    }
`

const InputFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
`

const ButtonContainer = styled.div`
    flex-basis: 50%;
    margin-top: 0;
    width: 50%;
    min-width: 10rem;

    @media ${device.mobileL} {
        flex-basis: 20%;
        width: 20%;
    }
`

const Input = styled.input`
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    border-bottom: 1px solid ${colors.tan};
    color: ${colors.tan};
    padding-bottom: 0.8rem;
    font-size: 16px;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    border-radius: 0;
`
const SubscribeButton = styled.button`
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    background: ${colors.tan};
    border: none;
    padding: 0.8rem 1rem;
    color: ${colors.black};
    width: 16rem;
    margin-top: 2rem;
    font-size: 1.6rem;
    width: 100%;
`

const StatusMessage = styled.span`
    color: ${colors.broom};
    font-size: 16px;
    margin-top: 1rem;
`
