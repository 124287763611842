import React from "react"
import PropTypes from "prop-types"
import emotionReset from "emotion-reset"
import { Global, css } from "@emotion/core"
import { globalFonts } from "../styles/fonts/fontFamilyDefinition"
import Header from "./Partials/header"
import Footer from "./Partials/footer"
import "./layout.css"

const Layout = ({ children }) => {
    return (
        <>
            <Global
                styles={css`
                    ${globalFonts}
                    ${emotionReset}

              *, *:before, *:after {
                        box-sizing: border-box;
                    }
                    html {
                        box-sizing: border-box;
                        font-size: 62.5%;
                        font-family: "Manrope";
                    }
                    body {
                        line-height: 1.5;
                        letter-spacing: 0;
                        background-color: #f8f7f5;
                        -moz-osx-font-smoothing: grayscale;
                        text-rendering: optimizeLegibility;
                        transition: background-color 0.3s ease-in;
                        will-change: background-color, color;
                    }

                    @-moz-document url-prefix() {
                        body .with-ff-fix {
                            font-weight: lighter !important;
                        }
                    }
                `}
            />
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
