import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { device } from "../../styles/device"
import AppWrapper from "../../styles/AppWrapper"
import { fontFamily } from "../../styles/fonts/fontFamily"
import logo from "../../images/johnnie-logo.svg"

const Header = () => (
    <>
        <NavBar>
            <NavContentWrapper>
                <LogoLink to="/">
                    <NavLogo
                        src={logo}
                        aria-label="Click on Logo to go to Homepage of JohnnieAiello.com"
                    />
                </LogoLink>
                <NavItemWrapper>
                    <NavItem activeClassName="active" to="/blog">
                        Blog
                    </NavItem>
                    <NavItem activeClassName="active" to="/about">
                        My Story
                    </NavItem>
                    <NavItem activeClassName="active" to="/contact">
                        Contact
                    </NavItem>
                </NavItemWrapper>
            </NavContentWrapper>
        </NavBar>
    </>
)

export default Header

const NavBar = styled.header`
    top: 0;
    z-index: 10;
`

const NavLogo = styled.img`
    width: 80px;

    @media ${device.mobileL} {
        padding: 20px 0;
    }
    @media ${device.tablet} {
        width: 100px;
    }
`

const LogoLink = styled(Link)`
    display: flex;
    align-self: center;
    margin-top: 25px;

    @media ${device.mobileL} {
        margin-right: 2em;
        margin-top: 0;
    }
`

const NavContentWrapper = styled(AppWrapper)`
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    display: flex;

    @media ${device.mobileL} {
        flex-direction: row;
    }
`

const NavItemWrapper = styled.div`
    display: flex;
    align-items: left;
    flex: 1;
    justify-content: flex-end;
    align-self: center;
    margin-top: 30px;

    @media ${device.mobileL} {
        margin-top: 0;
    }
`

const NavItem = styled(Link)`
    font-size: 14px;
    display: inline-flex;
    margin-right: 30px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
    color: ${colors.black};
    align-items: center;
    font-family: ${fontFamily.manrope};
    font-weight: 550;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;

    &.active {
        border-bottom: 2px solid ${colors.black};
    }
    &:last-child {
        margin-right: 0;
    }

    @media ${device.mobileM} {
        font-size: 16px;
    }
`
