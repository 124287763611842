export const colors = {
    // Global
    tan: "#f8f7f5",
    black: "#171717",
    purple: "#4C01D7",
    sand: "#f4f3f1",
    grey: "#9b9b9b",
    lightGrey: "#e3e3e3",
    white: "#FFFDFA",
    broom: "#f8ff1a"
}
